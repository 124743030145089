var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filters-container" },
    [
      _c(
        "div",
        { staticClass: "filters-items" },
        _vm._l(_vm.filters, function (filter, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t(filter.Text)))]),
              _c(_vm.resolveComponent(filter.InputType), {
                tag: "component",
                staticClass: "mt-1",
                attrs: { value: _vm.value[filter.Id], filter: filter },
                on: {
                  input: function ($event) {
                    return _vm.filterChanged(filter.Id, $event)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c("megau-button", {
        staticClass: "reset-filters",
        attrs: {
          classprop: "btn btn-customdanger color",
          icon: "times-circle",
          tooltip: _vm.$t("cancelallfilter"),
        },
        on: {
          handleClick: function ($event) {
            return _vm.clearAllFilters()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }