<template>
	<div class="views-container">
		<div>
			<span>{{ $t('dimension') }}</span>

			<b-form-select
				class="mt-1"
				:value="value.ActiveView"
				@input="activeViewChanged($event)"
				:options="views"
				value-field="Id"
				text-field="Text"
			></b-form-select>
		</div>

		<div v-if="actView.SubViewList && actView.SubViewList.length">
			<span>{{ $t('dimensionspecif') }}</span>

			<b-form-select
				class="mt-1"
				:value="value.ActiveSubView"
				@input="activeSubViewChanged($event)"
				:options="actView.SubViewList"
				value-field="Id"
				text-field="Text"
			></b-form-select>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: { type: Object, required: true },
		views: { type: Array, required: true },
	},

	computed: {
		actView() {
			return this.findViewByValue(this.value.ActiveView);
		},
	},

	methods: {
		activeViewChanged(ActiveView) {
			const view = this.findViewByValue(ActiveView);
			this.emitInput({
				ActiveView,
				ActiveSubView: view.DefaultSubView,
			});
		},

		activeSubViewChanged(ActiveSubView) {
			this.emitInput({
				ActiveView: this.value.ActiveView,
				ActiveSubView,
			});
		},

		findViewByValue(value, defaultView = {}) {
			const v = this.views.find((v) => v.Id === value);
			return v || defaultView;
		},

		emitInput(val) {
			this.$emit('input', val);
		},
	},
};
</script>

<style lang="scss" scoped>
.views-container {
	display: flex;
	flex-direction: column;
	width: 15rem;

	> div {
		margin: 0.3rem 0;
	}
}
</style>