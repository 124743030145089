<template>
	<b-form-select
		:value="value"
		@input="valueChange($event)"
		:options="acceptedValues"
		value-field="Id"
		text-field="Text"
	></b-form-select>
</template>

<script>
import { FilterMixin } from './filter.mixin';

export default {
	mixins: [FilterMixin],

	computed: {
		acceptedValues() {
			return [
				{
					Text: this.$t('select'),
					Value: null,
				},
				...this.filter.ValueItemList,
			];
		},
	},
};
</script>