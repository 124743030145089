var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "views-container" }, [
    _c(
      "div",
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("dimension")))]),
        _c("b-form-select", {
          staticClass: "mt-1",
          attrs: {
            value: _vm.value.ActiveView,
            options: _vm.views,
            "value-field": "Id",
            "text-field": "Text",
          },
          on: {
            input: function ($event) {
              return _vm.activeViewChanged($event)
            },
          },
        }),
      ],
      1
    ),
    _vm.actView.SubViewList && _vm.actView.SubViewList.length
      ? _c(
          "div",
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("dimensionspecif")))]),
            _c("b-form-select", {
              staticClass: "mt-1",
              attrs: {
                value: _vm.value.ActiveSubView,
                options: _vm.actView.SubViewList,
                "value-field": "Id",
                "text-field": "Text",
              },
              on: {
                input: function ($event) {
                  return _vm.activeSubViewChanged($event)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }