var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.data
        ? _c("v-client-table", {
            ref: _vm.tableName,
            staticClass: "stats-table",
            attrs: {
              data: _vm.dataWithSummary,
              name: _vm.tableName,
              columns: _vm.tableColumns,
              options: _vm.tableOptions,
            },
            on: {
              sorted: function ($event) {
                return _vm.sortChanged($event)
              },
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.columns, function (column, index) {
                  return {
                    key: column.Id,
                    fn: function (props) {
                      return [
                        props.row[_vm.tableColumnsValuePath[props.column]] ===
                        "?"
                          ? [
                              _c(
                                "span",
                                { key: index },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "empty-sum",
                                      attrs: { id: "tooltipTrigger-" + index },
                                    },
                                    [_vm._v("-")]
                                  ),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: "tooltipTrigger-" + index,
                                        triggers: "hover",
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t("summarydatadoes")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n\t\t\t\t" +
                                  _vm._s(
                                    _vm._f("cellFormat")(
                                      props.row[
                                        _vm.tableColumnsValuePath[props.column]
                                      ],
                                      column.Format
                                    )
                                  ) +
                                  "\n\t\t\t"
                              ),
                            ],
                      ]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }