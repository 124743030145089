export const baseOptions = {
	responsive: true,
	locale: 'sk-SK',
	scales: {
		yAxes: [
			{
				ticks: {
					callback: formatYlabels,
				},
			},
		],
	},
	tooltips: {
		enabled: true,
		callbacks: {
			label: formatTooltipLabel,
		},
	},
};

const numFormatter = new Intl.NumberFormat('sk-SK');

function formatTooltipLabel(tooltip, datasets) {
	const { value, datasetIndex } = tooltip;
	const ds = datasets.datasets[datasetIndex];
	return ` ${ds.label}: ${numFormatter.format(value)}`;
}

function formatYlabels(value, index, values) {
	return numFormatter.format(value);
}
