export function dataForGraph(apiData, apiSettings, i18n) {
	if (!apiData) return null;

	const gData = {
		labels: labels(apiData, apiSettings, i18n),
		datasets: datasets(apiData, apiSettings, i18n),
	};

	return gData;
}

function labels(data, settings, i18n) {
	return data.map((d) => i18n.t(d[settings.LabelsPath]));
}

function datasets(data, settings, i18n) {
	return settings.DataSets.map((ds) => ({
		label: i18n.t(ds.Label),
		data: data.map((d) => d[ds.ValuePath]),
		backgroundColor: ds.BackgroundColor,
		borderColor: ds.BorderColor,
		fill: ds.Fill,
	}));
}
