var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("date-range-picker", {
    staticClass: "d-block",
    attrs: {
      singleDatePicker: false,
      localeData: _vm.locale,
      ranges: false,
      autoApply: true,
      showDropdowns: true,
      opens: "right",
    },
    scopedSlots: _vm._u([
      {
        key: "input",
        fn: function (picker) {
          return [
            picker.startDate && picker.endDate
              ? _c(
                  "span",
                  { staticClass: "megau-dr-control ml-2" },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("date")(picker.startDate)) +
                          " - " +
                          _vm._s(_vm._f("date")(picker.endDate))
                      ),
                    ]),
                    _c("font-awesome-icon", {
                      attrs: { icon: "times" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clearFilter.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _c("span", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(_vm.$t("select"))),
                ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v
      },
      expression: "dateRange",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }