<template>
	<b-card v-if="!loading.structure && structure" class="bcard-wrapper">
		<b-card-title class="stats-title">
			<span>{{ $t(structure.main.Title) }}</span>

			<div class="stats-title-actions">
				<link-to-manual :tooltip="structure.main.ManualTooltip"></link-to-manual>

				<font-awesome-icon
					class="stats-reload-trigger"
					icon="sync-alt"
					@click="reloadFacts()"
					id="statsReloadTrigger"
				></font-awesome-icon>
				<b-tooltip target="statsReloadTrigger" triggers="hover" v-if="structure.main.ReloadInfo">
					<span>{{ structure.main.ReloadInfo.StartAt | dateTime }} ({{ structure.main.ReloadInfo.Duration }}s)</span>
				</b-tooltip>
			</div>
		</b-card-title>

		<div class="header-bar">
			<views-component v-if="activeView" v-model="activeView" :views="structure.main.Inputs.ViewList"></views-component>

			<filters-component
				v-if="filtersValues"
				v-model="filtersValues"
				:filters="structure.main.Inputs.FilterList"
			></filters-component>
		</div>

		<graph-component
			v-if="!loading.dataStructure && structure.data"
			:settings="structure.data.Graph"
			:data="graphStatistics"
			class="mt-5 mb-5"
		></graph-component>

		<table-component
			v-if="!loading.dataStructure && tableData"
			v-model="tableData"
			:statisticType="statisticType"
			:columns="structure.data.Table.ColumnList"
			:data="tableStatistics.Data"
			:summaryData="tableStatistics.Summary"
		></table-component>
	</b-card>
</template>

<script>
import vars from '@/services/helpers/variables';
import { FiltersComponent, GraphComponent, TableComponent, ViewsComponent } from './components';
import { StatisticService } from './services/statistic.service';

export default {
	components: {
		FiltersComponent,
		GraphComponent,
		TableComponent,
		ViewsComponent,
	},

	data() {
		return {
			loading: {
				structure: false,
				dataStructure: false,
				graphData: false,
				tableData: false,
				reloadFacts: false,
			},
			loader: null,
			statisticType: null,
			structure: null,
			graphStatistics: null,
			tableStatistics: [],
			pActiveView: null,
			pFiltersValues: null,
			pTableData: null,
		};
	},

	computed: {
		isLoading() {
			return (
				this.loading.structure ||
				this.loading.dataStructure ||
				this.loading.graphData ||
				this.loading.tableData ||
				this.loading.reloadFacts
			);
		},
		activeView: {
			get() {
				return this.pActiveView || {};
			},
			set(newValue) {
				this.pActiveView = newValue;
				// this.clearFilters();
				this.clearStatistics();
				this.activeViewChanged();
			},
		},
		filtersValues: {
			get() {
				return this.pFiltersValues || {};
			},
			set(newValue) {
				this.pFiltersValues = newValue;
				this.loadStatistics();
			},
		},
		tableData: {
			get() {
				return this.pTableData;
			},
			set(newValue) {
				this.pTableData = newValue;
				this.loadStatistics();
			},
		},
	},

	async created() {
		await this.statisticTypeChanged();
	},

	async beforeRouteUpdate(to, from, next) {
		await this.statisticTypeChanged(to);
		next();
	},

	methods: {
		loadingSet(value) {
			this.loading = {
				...this.loading,
				...value,
			};
			if (this.isLoading) {
				if (!this.loader) {
					this.loader = this.$loading.show();
				}
			} else if (this.loader) {
				this.loader.hide();
				this.loader = null;
			}
		},

		async reloadFacts() {
			this.loadingSet({ reloadFacts: true });
			this.structure.main.ReloadInfo = await StatisticService.reloadFacts(this.statisticType);
			this.loadingSet({ reloadFacts: false });

			await this.statisticTypeChanged();
		},

		async statisticTypeChanged(route = null) {
			this.statisticType = (route || this.$route).params.statistic;

			this.loadingSet({ dataStructure: true, structure: true });
			this.structure = await this.loadStructure();
			this.loadingSet({ dataStructure: false, structure: false });

			this.pActiveView = this.structure.main.ActiveView;
			this.pFiltersValues = this.initFilters(this.structure.main.Inputs.FilterList);
			this.pTableData = this.initTableData(this.structure.data.Table);
			this.loadStatistics();
		},

		async activeViewChanged() {
			this.loadingSet({ dataStructure: true });
			this.structure.data = await this.loadDataStructure();
			this.loadingSet({ dataStructure: false });

			this.pTableData = this.initTableData(this.structure.data.Table);
			this.loadStatistics();
		},

		async loadStructure() {
			return StatisticService.getStructure(this.statisticType);
		},

		async loadDataStructure() {
			return StatisticService.getDataStructure(this.statisticType, this.activeView.ActiveView, this.activeView.ActiveSubView);
		},

		async loadStatistics() {
			this.loadingSet({ graphData: true });
			this.loadingSet({ tableData: true });

			const [tableStatistics] = await Promise.all([this.loadTableStatistics()]);
			this.tableStatistics = tableStatistics;
			this.graphStatistics = tableStatistics.Data;

			this.loadingSet({ graphData: false });
			this.loadingSet({ tableData: false });
		},

		clearStatistics() {
			this.tableStatistics = [];
			this.graphStatistics = null;
		},

		clearFilters() {
			this.pFiltersValues = null;
		},

		loadTableStatistics() {
			const data = {
				statisticType: this.statisticType,
				active: this.activeView,
				tableData: this.tableData,
				filters: this.filtersValues,
			};

			return StatisticService.getTableStatistics(data);
		},

		initFilters(filters) {
			return filters.reduce((obj, filter) => {
				obj[filter.Id] = filter.DefaultValue;
				return obj;
			}, {});
		},

		initTableData(tableStructure) {
			return {
				orderBy: tableStructure.OrderBy.Column,
				ascending: tableStructure.OrderBy.Ascending,
				perPage: vars.tableVars.defaultPerPage,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.stats-title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.stats-title-actions {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.stats-reload-trigger {
	color: #6c757d;
	font-size: 1.5rem;
	line-height: 1.5rem;
	cursor: pointer;
}

.header-bar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
</style>