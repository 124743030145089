import { render, staticRenderFns } from "./views.vue?vue&type=template&id=69fb2360&scoped=true&"
import script from "./views.vue?vue&type=script&lang=js&"
export * from "./views.vue?vue&type=script&lang=js&"
import style0 from "./views.vue?vue&type=style&index=0&id=69fb2360&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69fb2360",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\client\\Admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('69fb2360')) {
      api.createRecord('69fb2360', component.options)
    } else {
      api.reload('69fb2360', component.options)
    }
    module.hot.accept("./views.vue?vue&type=template&id=69fb2360&scoped=true&", function () {
      api.rerender('69fb2360', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/statistics/components/views.vue"
export default component.exports