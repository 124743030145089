<template>
	<div>
		<v-client-table
			class="stats-table"
			v-if="data"
			:ref="tableName"
			:data="dataWithSummary"
			:name="tableName"
			:columns="tableColumns"
			:options="tableOptions"
			@sorted="sortChanged($event)"
		>
			<template v-for="(column, index) in columns" v-slot:[column.Id]="props">
				<template v-if="props.row[tableColumnsValuePath[props.column]] === '?'">
					<span :key="index">
						<!-- <font-awesome-icon icon="question-circle" :id="`tooltipTrigger-${index}`"></font-awesome-icon> -->
						<span :id="`tooltipTrigger-${index}`" class="empty-sum">-</span>
						<b-tooltip :target="`tooltipTrigger-${index}`" triggers="hover">
							<span v-html="$t('summarydatadoes')"></span>
						</b-tooltip>
					</span>
				</template>
				<template v-else>
					{{ props.row[tableColumnsValuePath[props.column]] | cellFormat(column.Format) }}
				</template>
			</template>
		</v-client-table>
	</div>
</template>

<script>
import vars from '@/services/helpers/variables';
import countryGlobalFilter from '@/mixins/country-global-filter.mixin';
import serviceCommon from '@/services/service/common.service';

export default {
	mixins: [countryGlobalFilter],

	props: {
		value: { type: Object, required: true },
		statisticType: { type: String, required: true },
		columns: { type: Array, required: true },
		data: { type: Array, default: () => [] },
		summaryData: { type: Array, default: () => [] },
	},

	computed: {
		dataWithSummary() {
			return [[...this.summaryData, '__summary__'], ...this.data];
		},

		tableName() {
			return `${this.statisticType}Table`;
		},

		tableColumns() {
			return this.columns.map((c) => c.Id);
		},

		tableColumnsValuePath() {
			return this.columns.reduce((obj, c) => {
				obj[c.Id] = c.ValuePath;
				return obj;
			}, {});
		},

		tableOptions() {
			return {
				orderBy: this.orderBy,
				filterByColumn: true,
				columnsDropdown: true,
				pagination: vars.tableVars.paginationSetting,
				sortIcon: vars.tableVars.sortIcons,
				texts: serviceCommon.recordsPaginationsTexts(),
				hiddenColumns: this.hiddenColumns,
				sortable: this.sortableColumnsName,
				filterable: false,
				perPage: this.value.perPage,
				headings: this.headings,
				customSorting: this.customSorting,
				columnsClasses: this.columnsClasses,
				rowClassCallback: (row) => (row.includes('__summary__') ? 'stats-summary-row' : ''),
			};
		},

		hiddenColumns() {
			return this.columns.filter((c) => !!c.IsHidden).map((c) => c.Id);
		},

		sortableColumns() {
			return this.columns.filter((c) => !!c.IsSortable);
		},
		sortableColumnsName() {
			return this.sortableColumns.map((c) => c.Id);
		},

		headings() {
			return this.columns.reduce((h, c) => {
				h[c.Id] = this.formatColumnHeading(c);
				return h;
			}, {});
		},

		orderBy() {
			return {
				column: this.value.orderBy,
				ascending: this.value.ascending,
			};
		},

		customSorting() {
			return this.sortableColumns.reduce((obj, c) => {
				obj[c.Id] = (ascending) => (a, b) => false;
				return obj;
			}, {});
		},

		columnsClasses() {
			return this.columns.reduce((obj, c) => {
				const alClass = alignmentClass(c.Alignment);
				if (alClass) {
					obj[c.Id] = alClass;
				}
				return obj;
			}, {});

			function alignmentClass(alignment) {
				switch (alignment) {
					case 'left':
						return 'text-left ';
					case 'center':
						return 'text-center ';
					case 'right':
						return 'text-right ';
					default:
						return null;
				}
			}
		},
	},

	methods: {
		sortChanged(ev) {
			this.$emit('input', {
				...this.value,
				orderBy: ev.column,
				ascending: ev.ascending,
			});
		},

		formatColumnHeading(column) {
			let heading = this.$i18n.t(column.Text);

			if (column.Format === 'percent') {
				heading += ' %';
			}

			return heading;
		},
	},

	filters: {
		cellFormat(value, format) {
			switch (format) {
				case 'integer':
					return integerFormat(value);
				case 'currency':
					return currencyFormat(value);
				case 'percent':
					return percentFormat(value);
				default:
					return value;
			}

			function integerFormat(value) {
				if (isNaN(value)) {
					return value;
				}

				const v = new Intl.NumberFormat('sk-SK');
				return v.format(value);
			}

			function currencyFormat(value) {
				if (isNaN(value)) {
					return value;
				}

				const v = new Intl.NumberFormat('sk-SK', { minimumFractionDigits: 2 });
				return v.format(value);
			}

			function percentFormat(value) {
				if (value || value == 0) {
					const v = new Intl.NumberFormat('sk-SK', { minimumFractionDigits: 2 });
					return v.format(value);
				}
				return '';
			}
		},
	},
};
</script>

<style lang="scss">
.stats-table {
	> div.row:first-child > div.col-md-12 {
		justify-content: flex-end;
	}

	.stats-summary-row {
		font-weight: bold;

		svg {
			color: #6c757d;
		}

		.empty-sum {
			padding-left: 5px;
			cursor: default;
		}
	}

	.VueTables__sort-icon {
		float: right;
		margin-left: 1em;

		&.fa::before {
			width: 10px;
			display: inline-block;
		}
		&.fa-sort::before {
			content: url('~@/assets/faicons/sort.svg');
		}
		&.fa-sort-asc::before {
			content: url('~@/assets/faicons/sort-up.svg');
		}
		&.fa-sort-desc::before {
			content: url('~@/assets/faicons/sort-down.svg');
		}
	}
}
</style>