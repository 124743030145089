<template>
	<date-range-picker
		v-model="dateRange"
		:singleDatePicker="false"
		:localeData="locale"
		:ranges="false"
		:autoApply="true"
		:showDropdowns="true"
		opens="right"
		class="d-block"
	>
		<template #input="picker">
			<span v-if="picker.startDate && picker.endDate" class="megau-dr-control ml-2">
				<span>{{ picker.startDate | date }} - {{ picker.endDate | date }}</span>
				<font-awesome-icon icon="times" @click.stop="clearFilter"></font-awesome-icon>
			</span>
			<span v-else class="ml-2">{{ $t('select') }}</span>
		</template>
	</date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
import { FilterMixin } from './filter.mixin';

moment.locale('sk');

export default {
	mixins: [FilterMixin],

	components: { DateRangePicker },

	data() {
		return {
			locale: {
				format: this.$store.state.lang.DateFormat, //fomart of the dates displayed
				applyLabel: this.$i18n.t('delete2315'),
				cancelLabel: this.$i18n.t('cancel2314'),
				separator: ' - ', //separator between the two ranges
				daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
				firstDay: 1, //ISO first day of week - see moment documenations for details
			},
		};
	},

	computed: {
		dateRange: {
			get() {
				const [startDate, endDate] = (this.value || '|').split('|');

				return {
					startDate,
					endDate,
				};
			},
			set(newValue) {
				const startDate = moment(newValue.startDate).format('YYYY-MM-DD');
				const endDate = moment(newValue.endDate).format('YYYY-MM-DD');
				this.valueChange(`${startDate}|${endDate}`);
			},
		},
	},

	methods: {
		clearFilter() {
			this.valueChange(null);
			return false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '~vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css';
.vue-daterange-picker {
	width: 100%;
	white-space: nowrap;
}

.megau-dr-control {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	svg {
		align-self: center;
	}
}
</style>
