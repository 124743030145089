var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.graphData
        ? _c(_vm.resolvedComponent, {
            tag: "component",
            attrs: {
              chartData: _vm.graphData,
              chartOptions: _vm.graphOptions,
              height: _vm.graphHeight,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }