export const FilterMixin = {
	props: {
		value: { required: true },
		filter: { type: Object, required: true },
	},

	methods: {
		valueChange(value) {
			this.$emit('input', value);
		},
	},
};
