<template>
	<div class="filters-container">
		<div class="filters-items">
			<div v-for="(filter, index) in filters" :key="index">
				<span>{{ $t(filter.Text) }}</span>

				<component
					class="mt-1"
					:is="resolveComponent(filter.InputType)"
					:value="value[filter.Id]"
					:filter="filter"
					@input="filterChanged(filter.Id, $event)"
				></component>
			</div>
		</div>

		<megau-button
			class="reset-filters"
			classprop="btn btn-customdanger color"
			icon="times-circle"
			:tooltip="$t('cancelallfilter')"
			@handleClick="clearAllFilters()"
		></megau-button>
	</div>
</template>

<script>
import { FilterAutocomplete, FilterDaterange, FilterDropdown } from './filters/index';

export default {
	props: {
		filters: { type: Array, required: true },
		value: { type: Object, required: true },
	},

	computed: {
		filtersValues: {
			get() {
				return this.value;
			},
			set(newValue) {
				this.$emit('input', newValue);
			},
		},
	},

	methods: {
		resolveComponent(type) {
			switch (type) {
				case 'autocomplete':
					return FilterAutocomplete;
				case 'daterange':
					return FilterDaterange;
				case 'dropdown':
					return FilterDropdown;
				default:
					throw 'Unknown filter component type';
			}
		},

		filterChanged(filterName, filterValue) {
			this.filtersValues = {
				...this.filtersValues,
				[filterName]: filterValue,
			};
		},

		clearAllFilters() {
			this.filtersValues = Object.entries(this.filtersValues).reduce((o, [filterName]) => {
				const filter = this.filters.find((f) => f.Id === filterName);
				if (filter) {
					o[filterName] = filter.DefaultValue;
				}
				return o;
			}, {});
		},
	},
};
</script>

<style lang="scss" scoped>
.filters-container {
	display: flex;
	flex-direction: row;
	align-items: top;
	margin-left: 2rem;

	.filters-items {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		flex-wrap: wrap;

		> div {
			margin: 0.3rem 0.5rem;
			flex: 1 0 150px;
		}
	}

	.reset-filters {
		margin-top: 26px;
	}
}
</style>