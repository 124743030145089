var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autocomplete" },
    [
      _c("filter-autocomplete", {
        ref: "ac",
        attrs: {
          url: _vm.url,
          initValue: _vm.value,
          onSelect: function (e) {
            return _vm.valueSelected(e)
          },
          placeholder: _vm.$t("select"),
          anchor: "Text",
        },
      }),
      _vm.showClear
        ? _c(
            "span",
            {
              staticClass: "autocomplete__clear",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.valueChange(null)
                },
              },
            },
            [_c("font-awesome-icon", { attrs: { icon: "times" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }