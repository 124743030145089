import { baseOptions } from '../graph/base-options';
const deepMerge = require('deepmerge');

export const chartMixin = {
	props: {
		chartOptions: { type: Object, default: () => {} },
	},

	data() {
		return {
			specificOptions: {},
		};
	},

	computed: {
		options() {
			return deepMerge.all([baseOptions, this.specificOptions, this.chartOptions]);
		},
	},

	mounted() {
		this.renderChart(this.chartData, this.options);
	},
};
