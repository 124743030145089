var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-form-select", {
    attrs: {
      value: _vm.value,
      options: _vm.acceptedValues,
      "value-field": "Id",
      "text-field": "Text",
    },
    on: {
      input: function ($event) {
        return _vm.valueChange($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }