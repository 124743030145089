<template>
	<div>
		<component
			v-if="graphData"
			:is="resolvedComponent"
			:chartData="graphData"
			:chartOptions="graphOptions"
			:height="graphHeight"
		></component>
	</div>
</template>

<script>
import { Bar, Line } from './charts/index';
import { dataForGraph } from './graph/adapter';

export default {
	props: {
		// statisticType: { type: String, required: true },
		settings: { type: Object, required: true },
		data: { type: Array, default: null },
	},

	data() {
		return {
			graphHeight: 100,
		};
	},

	computed: {
		resolvedComponent() {
			return this.resolveComponent(this.settings.Type);
		},

		graphOptions() {
			return {};
		},

		graphData() {
			return dataForGraph(this.data, this.settings, this.$i18n);
		},
	},

	methods: {
		resolveComponent(type) {
			switch (type) {
				case 'bar':
					return Bar;
				case 'line':
					return Line;
				default:
					throw 'Unknown graph component type';
			}
		},
	},
};
</script>