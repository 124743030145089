import { Line, mixins } from 'vue-chartjs';
import { chartMixin } from './chart.mixin';
const { reactiveProp } = mixins;

const lineChartOptions = {
	tooltips: {
		mode: 'index',
	},
};

export default {
	extends: Line,

	mixins: [reactiveProp, chartMixin],

	data() {
		return {
			specificOptions: lineChartOptions,
		};
	},
};
