<template>
	<div class="autocomplete">
		<filter-autocomplete
			ref="ac"
			:url="url"
			:initValue="value"
			:onSelect="(e) => valueSelected(e)"
			:placeholder="$t('select')"
			anchor="Text"
		></filter-autocomplete>
		<span v-if="showClear" class="autocomplete__clear" @click.stop="valueChange(null)">
			<font-awesome-icon icon="times"></font-awesome-icon>
		</span>
	</div>
</template>

<script>
import FilterAutocomplete from '@/components/common/filter-autocomplete';

import { FilterMixin } from './filter.mixin';

export default {
	mixins: [FilterMixin],

	components: {
		FilterAutocomplete,
	},

	computed: {
		showClear() {
			return this.value !== null;
		},

		url() {
			return process.env.VUE_APP_ROOT_API + this.filter.AcceptedValuesUrl;
		},
	},

	watch: {
		value(val) {
			if (val === null) {
				this.$refs.ac.clear();
			}
		},
	},

	methods: {
		valueSelected(value) {
			this.valueChange(value.Id);
		},
	},
};
</script>

<style lang="scss" scoped>
.autocomplete {
	display: flex;
	flex-direction: row;
	align-items: center;

	.field-autocomplete {
		flex-grow: 1;
	}

	&__clear {
		cursor: pointer;
		margin-left: 0.5rem;
	}
}
</style>