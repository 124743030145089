var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading.structure && _vm.structure
    ? _c(
        "b-card",
        { staticClass: "bcard-wrapper" },
        [
          _c("b-card-title", { staticClass: "stats-title" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t(_vm.structure.main.Title)))]),
            _c(
              "div",
              { staticClass: "stats-title-actions" },
              [
                _c("link-to-manual", {
                  attrs: { tooltip: _vm.structure.main.ManualTooltip },
                }),
                _c("font-awesome-icon", {
                  staticClass: "stats-reload-trigger",
                  attrs: { icon: "sync-alt", id: "statsReloadTrigger" },
                  on: {
                    click: function ($event) {
                      return _vm.reloadFacts()
                    },
                  },
                }),
                _vm.structure.main.ReloadInfo
                  ? _c(
                      "b-tooltip",
                      {
                        attrs: {
                          target: "statsReloadTrigger",
                          triggers: "hover",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateTime")(
                                _vm.structure.main.ReloadInfo.StartAt
                              )
                            ) +
                              " (" +
                              _vm._s(_vm.structure.main.ReloadInfo.Duration) +
                              "s)"
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "header-bar" },
            [
              _vm.activeView
                ? _c("views-component", {
                    attrs: { views: _vm.structure.main.Inputs.ViewList },
                    model: {
                      value: _vm.activeView,
                      callback: function ($$v) {
                        _vm.activeView = $$v
                      },
                      expression: "activeView",
                    },
                  })
                : _vm._e(),
              _vm.filtersValues
                ? _c("filters-component", {
                    attrs: { filters: _vm.structure.main.Inputs.FilterList },
                    model: {
                      value: _vm.filtersValues,
                      callback: function ($$v) {
                        _vm.filtersValues = $$v
                      },
                      expression: "filtersValues",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          !_vm.loading.dataStructure && _vm.structure.data
            ? _c("graph-component", {
                staticClass: "mt-5 mb-5",
                attrs: {
                  settings: _vm.structure.data.Graph,
                  data: _vm.graphStatistics,
                },
              })
            : _vm._e(),
          !_vm.loading.dataStructure && _vm.tableData
            ? _c("table-component", {
                attrs: {
                  statisticType: _vm.statisticType,
                  columns: _vm.structure.data.Table.ColumnList,
                  data: _vm.tableStatistics.Data,
                  summaryData: _vm.tableStatistics.Summary,
                },
                model: {
                  value: _vm.tableData,
                  callback: function ($$v) {
                    _vm.tableData = $$v
                  },
                  expression: "tableData",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }